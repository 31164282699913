import React from "react";

const Navbar = () => {
    return (
        <>
          <div className="navbar p-3 flex items-center">
             <h2 className="navbar__title text-sm md:text-xl">Extract Data</h2>
          </div>
        </>
    )
}

export default Navbar;