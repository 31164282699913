import React from "react";

const Dropdown = ({
  className = "",
  options,
  name = "",
  value = "",
  callback,
  disabled=false
}) => {
  const callCallback = (event) => {
    callback(event);
  };

  return (
    <>
      <select
        name={name}
        value={value}
        className={`dropdown_list ${className} p-2 rounded `}
        onChange={callCallback}
        defaultValue={""}
        disabled={disabled}
      >
        <option value="">Please Select</option>
        {options.map((option, index) => {
          return (
            <>
              <option value={option.value}>{option.key}</option>
            </>
          );
        })}
      </select>
    </>
  );
};

export default Dropdown;
