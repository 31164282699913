import React, { useState, useRef } from "react";
import Dropdown from "./utils/Dropdown";
import axios from "axios";

let step1_drop1 = [
  { key: "JSON", value: "json" },
  { key: "PDF", value: "pdf" },
];

let json_step2_drop1 = [
  { key: "Web URL", value: "link" },
  { key: "File", value: "file" },
];

let json_step3_drop1 = [
  { key: "PDF", value: "pdf_file" },
  { key: "Meta Data", value: "metadata" },
];

const DataSource = () => {
  const [dataSource, setDataSource] = useState({
    type: "",
    pdf_files: [],
    json_source_type: "",
    json_source_value: "", //can hold the web server url || single json file
    json_meta_data: [],
    haveIndex: "",
    index_name: "",
    index_created: false,
  });
  const metadataCounter = useRef(0);
  const [loading, setLoading] = useState(false);
  const [filesPath, setFilesPath] = useState([]);

  const addMetadataField = () => {
    metadataCounter.current++;
    setDataSource((prevDataSource) => {
      return {
        ...prevDataSource,
        json_meta_data: [
          ...prevDataSource.json_meta_data,
          {
            id: metadataCounter.current,
            json_metadata_source_type: "",
            json_metadata_source_field: "",
          },
        ],
      };
    });
  };

  const deleteMetadataField = (id) => {
    setDataSource((prevDataSource) => {
      return {
        ...prevDataSource,
        json_meta_data: prevDataSource.json_meta_data.filter((data) => {
          return data.id !== id;
        }),
      };
    });
  };

  const updateDataSource = (event, index = null) => {
    const { name, value, type } = event.target;

    if (name === "haveIndex") {
      setDataSource({
        type: "",
        pdf_files: [],
        json_source_type: "",
        json_source_value: "",
        json_meta_data: [],
        haveIndex: value,
        index_name: "",
        index_created: false,
      });
      return;
    }

    //if the type is getting updated, reset all the values
    if (name === "type") {
      setDataSource({
        type: value,
        pdf_files: [],
        json_source_type: "",
        json_source_value: "",
        json_meta_data: [],
        haveIndex: dataSource.haveIndex,
        index_name: dataSource.index_name,
        index_created: dataSource.index_created,
      });
      return;
    }

    if (name === "json_source_type") {
      setDataSource((prev) => {
        return { ...prev, json_source_type: value, json_source_value: "" };
      });
      return;
    }

    let newData = { ...dataSource };

    if (index !== null) {
      newData["json_meta_data"] = [...newData["json_meta_data"]];
      newData["json_meta_data"][index] = {
        ...newData["json_meta_data"][index],
        [name]: value,
      };
      setDataSource(newData);
      return;
    }

    newData[name] = type === "file" ? [...event.target.files] : value;
    setDataSource(newData);
    return;
  };

  const createIndex = async () => {
    try {
      //first make sure the inputs are available
      if (!dataSource.index_name.trim()) {
        alert("Enter your index name!");
        return;
      }

      const response = await axios.post(
        `${window.location.protocol}//${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}/pipeline/create-index`,
        {
          index: dataSource.index_name,
        }
      );

      console.log("Response : ", response);

      if (response.data.success) {
        alert(response.data.message);
        setDataSource((prev) => {
          return { ...prev, index_created: true };
        });
        return;
      }
    } catch (err) {
      console.log("Error while creating index: ", err);
      //handle errors
      if (err?.response?.data?.success === false) {
        alert(err.response.data?.message);
        return;
      }

      if (err?.name === "AxiosError") {
        alert("Sorry, unable to create index at this moment!");
        return;
      }
    }
  };

  const validateInputs = () => {
    //check the initial source type
    if (dataSource.type === "") {
      alert("Please select valid data source!");
      return false;
    }

    if (!dataSource.index_name.trim()) {
      alert("Enter valid index name!");
      return;
    }

    //  2. json type
    // verifying that user enters proper metadata
    if (dataSource.type === "json") {
      for (let i = 0; i < dataSource.json_meta_data.length; i++) {
        if (
          dataSource.json_meta_data[i].json_metadata_source_type === "" ||
          dataSource.json_meta_data[i].json_metadata_source_field.trim() === ""
        ) {
          alert("Please enter proper metadata!");
          return false;
        }
      }
    }

    return true;
  };

  //function for extracting data -> creating embeddings -> store in the vector database
  const submitData = async () => {
    try {
      //first make sure the inputs are available
      if (!validateInputs()) {
        return;
      }

      const response = await axios.post(
        `${window.location.protocol}//${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}/pipeline/execute-pipeline`,
        {
          type: dataSource.type,
          filesPath,
          index: dataSource.index_name,
          metadata:
            dataSource.type === "json" ? dataSource.json_meta_data : null, //if the data source is json, then we will have metadata fields
          //PDF dont have metadata for now
        }
      );

      console.log("Response : ", response);

      if (response.data.success) {
        alert(response.data.message);
        setDataSource({
          type: "",
          pdf_files: [],
          json_source_type: "",
          json_source_value: "",
          json_meta_data: [],
          haveIndex: "",
          index_created: false,
          index_name: "",
        });
        setFilesPath([]);
        return;
      }
    } catch (err) {
      //handle errors
      if (err?.response?.data?.success === false) {
        alert(err.response.data?.message);
        return;
      }

      if (err?.name === "AxiosError") {
        alert("Sorry, something went wrong!");
        return;
      }
      return;
    }
  };

  //function for uploading json related content
  const uploadJsonContent = async () => {
    try {
      //first make sure the inputs are available
      // 2. json type
      if (dataSource.json_source_type === "") {
        alert("Please select valid source type for JSON!");
        return;
      }

      let isLink = dataSource.json_source_type === "link" ? true : false;

      if (isLink && dataSource.json_source_value.trim() === "") {
        alert("Please input valid source value for JSON!");
        return;
      }

      if (!isLink && dataSource.json_source_value.length === 0) {
        alert("Please select JSON file!");
        return;
      }

      if (
        !isLink &&
        dataSource.json_source_value[0].type !== "application/json"
      ) {
        alert("Upload only JSON file!");
        return;
      }

      const response = await axios.post(
        `${window.location.protocol}//${process.env.REACT_APP_SERVER_HOST}:${
          process.env.REACT_APP_SERVER_PORT
        }/json/${isLink ? "link" : "file"}`,
        {
          json_source_value: isLink
            ? dataSource.json_source_value
            : dataSource.json_source_value[0],
        },
        {
          headers: {
            "Content-Type": isLink
              ? "application/x-www-form-urlencoded"
              : "multipart/form-data",
          },
        }
      );

      console.log("Response : ", response);

      //handle files information
      setFilesPath((prevData) => {
        return [...prevData, response.data.filePath];
      });

      alert(response.data.message);
      return;
    } catch (err) {
      console.log("Error while uploading files : ", err);
      //handle errors
      if (err?.response?.data?.success === false) {
        alert(err.response.data?.message);
        return;
      }

      if (err?.name === "AxiosError") {
        alert("Sorry, something went wrong!");
        return;
      }
    }
  };

  //function for uploading PDFs
  const uploadPDFContent = async () => {
    try {
      //first make sure the inputs are available
      // 1. PDF type
      if (dataSource.type === "pdf") {
        if (dataSource.pdf_files.length === 0) {
          alert("Please select PDF files!");
          return;
        }
      }

      //verify if the user is uploading pdf files only
      let onlyPDF = true;

      let formData = new FormData();
      dataSource.pdf_files.forEach((file) => {
        if (file.type !== "application/pdf") onlyPDF = false;
        formData.append("pdf_files", file);
      });

      if (!onlyPDF) {
        alert("Upload only PDF files!");
        return;
      }

      const response = await axios.post(
        `${window.location.protocol}//${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}/pdf/files`,
        formData
      );

      console.log("Response : ", response);

      //handle files information
      setFilesPath((prevData) => {
        return [...prevData, ...response.data.filePath];
      });

      alert(response.data.message);
      return;
    } catch (err) {
      console.log("Error while uploading files : ", err);
      //handle errors
      if (err?.response?.data?.success === false) {
        alert(err.response.data?.message);
        return;
      }

      if (err?.name === "AxiosError") {
        alert("Sorry, something went wrong!");
        return;
      }
    }
  };

  return (
    <>
      <div className="data_source p-6">
        <div className="data_source__index mb-3">
          <p className="data_source__index--need my-1 text-white">
            Do you want to create a new index ?
          </p>
          <div className="data_source__index__check flex gap-4">
            <label className="text-white">
              <input
                type="radio"
                className="mx-1"
                name="haveIndex"
                value={"true"}
                checked={dataSource.haveIndex == "true"}
                onChange={updateDataSource}
              />
              Yes
            </label>
            <label className="text-white">
              <input
                type="radio"
                className="mx-1"
                name="haveIndex"
                value={"false"}
                checked={dataSource.haveIndex == "false"}
                onChange={updateDataSource}
              />
              No
            </label>
          </div>
          {dataSource.haveIndex !== "" && (
            <>
              <p className="data_source__index--name my-1 text-white">
                Enter your index name :
              </p>
              <div className="flex gap-4">
                <input
                  className="text data_source__input_text"
                  type="text"
                  placeholder="Enter Index Name"
                  value={dataSource.index_name}
                  name="index_name"
                  onChange={updateDataSource}
                  // disable if you want to create a new index and the index has been created successfully
                  disabled={dataSource.index_created}
                />
                {dataSource.haveIndex === "true" && (
                  <>
                    <button
                      className="btn"
                      onClick={() => createIndex()}
                      disabled={dataSource.index_created}
                    >
                      Create
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <p className="data_source__heading text-sm lg:text-lg text-white">
          For extracting data efficiently, please follow given steps:{" "}
        </p>
        <ol className="px-4 text-white">
          <li style={{ listStyleType: "inside" }} className="my-5">
            <p className="data_source__step1__title my-1">
              Select Data Source Type
            </p>
            <Dropdown
              className={"data_source__step1_drop1"}
              options={step1_drop1}
              name={"type"}
              value={dataSource.type}
              callback={updateDataSource}
              disabled={filesPath.length !== 0}
            />
          </li>
          {/* JSON related  */}
          {dataSource.type === "json" && (
            <>
              <li style={{ listStyleType: "inside" }} className="my-5">
                <p className="data_source__step2__title my-1">
                  Select Data Source
                </p>
                <div className="flex gap-3">
                  <Dropdown
                    className={"data_source__step2_drop1"}
                    options={json_step2_drop1}
                    name={"json_source_type"}
                    value={dataSource.json_source_type}
                    callback={updateDataSource}
                    disabled={filesPath.length !== 0}
                  />
                  {dataSource.json_source_type === "link" && (
                    <>
                      <input
                        className="text data_source__input_text"
                        type="text"
                        placeholder="Enter Web URL"
                        value={dataSource.json_source_value}
                        name="json_source_value"
                        onChange={updateDataSource}
                        disabled={filesPath.length !== 0}
                      />
                    </>
                  )}
                  {dataSource.json_source_type === "file" && (
                    <>
                      <div className="flex items-start">
                        <label
                          htmlFor="file-upload"
                          className="custom_file_upload"
                        >
                          Choose
                        </label>
                        <input
                          className="file data_source__input_file"
                          type="file"
                          name="json_source_value"
                          onChange={updateDataSource}
                          id={"file-upload"}
                        />
                        <div className="flex flex-col mx-3">
                          {dataSource.json_source_value &&
                            dataSource.json_source_value?.map((file, index) => {
                              return (
                                <>
                                  <p>
                                    {index + 1}) {file.name}
                                  </p>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  )}
                  {dataSource.json_source_type !== "" && (
                    <>
                      <button
                        className="btn"
                        onClick={uploadJsonContent}
                        disabled={filesPath.length !== 0}
                      >
                        {dataSource.json_source_type === "link"
                          ? "Fetch Data"
                          : "Upload File"}
                      </button>
                    </>
                  )}
                </div>
              </li>
              <li style={{ listStyleType: "inside" }} className="my-5">
                <p className="data_source__step3__title my-1">
                  Add Key-Value pairs
                </p>
                <button
                  className="btn btn--add_meta_data mb-1"
                  onClick={addMetadataField}
                  disabled={filesPath.length === 0}
                >
                  Add Field
                </button>
                {dataSource.json_meta_data.map((data, index) => {
                  return (
                    <div className="flex gap-3 my-2" key={index}>
                      <Dropdown
                        className={"data_source__step3_drop1"}
                        options={json_step3_drop1}
                        name={`json_metadata_source_type`}
                        value={
                          dataSource.json_meta_data[index]
                            .json_metadata_source_type
                        }
                        callback={(e) => updateDataSource(e, index)}
                      />
                      <input
                        className="text data_source__input_text"
                        type="text"
                        placeholder="Enter JSON Field Name"
                        name={`json_metadata_source_field`}
                        onChange={(e) => updateDataSource(e, index)}
                        value={
                          dataSource.json_meta_data[index]
                            .json_metadata_source_field
                        }
                      />
                      <button
                        className="btn btn--delete_meta_data bg-[#e87066]"
                        onClick={() => deleteMetadataField(data.id)}
                      >
                        Delete
                      </button>
                    </div>
                  );
                })}
              </li>
            </>
          )}
          {/* PDF related  */}
          {dataSource.type === "pdf" && (
            <>
              <li style={{ listStyleType: "inside" }} className="my-5">
                <p className="data_source__step2__title my-1">
                  Select files to upload
                </p>
                <div className="flex items-start">
                  <label htmlFor="file-upload" className="custom_file_upload">
                    Choose
                  </label>
                  <input
                    className="file data_source__input_file"
                    type="file"
                    name={"pdf_files"}
                    multiple
                    onChange={updateDataSource}
                    id="file-upload"
                    disabled={filesPath.length !== 0}
                  />
                  <div className="flex flex-col mx-3">
                    {dataSource.pdf_files.map((file, index) => {
                      return (
                        <>
                          <p>
                            {index + 1}) {file.name}
                          </p>
                        </>
                      );
                    })}
                  </div>
                </div>

                <button
                  className="btn my-2"
                  onClick={uploadPDFContent}
                  disabled={filesPath.length !== 0}
                >
                  Upload Files
                </button>
              </li>
            </>
          )}
        </ol>
        <button
          className="btn"
          onClick={submitData}
          disabled={filesPath.length === 0}
        >
          Extract Data
        </button>
      </div>
    </>
  );
};

export default DataSource;
