import React from 'react';
import Navbar from './components/Navbar';
import DataSource from './components/DataSource';

function App() {
  return (
  <div className="app_outer flex justify-center">
    <div className="app">
      <Navbar />
      <DataSource />
    </div>
  </div>
  );
}

export default App;
